import { useMemo } from "react";

import { useLocation } from "react-router-dom";
import useSWR from "swr";

import { SERIES_ROOT } from "src/constants";
import { SERVICE_1, callLambda } from "src/utils/lambda";

export default () => {
  const location = useLocation();
  const slug = useMemo(() => {
    const series_root = [...new Set(Object.values(SERIES_ROOT))];
    if (!series_root.some(dir => location.pathname.startsWith(dir))) return null;
    return location.pathname.split("/")[2];
  }, [location]);
  return useSWR(
    [slug, "portalItem"],
    () => (slug ? callLambda({
      method   : "get",
      service  : SERVICE_1,
      url      : "getPortalInfo",
      unauthed : true
    }) : null)
  );
};
