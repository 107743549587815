import React from "react";

import { browserTracingIntegration } from "@sentry/browser";
import { captureConsoleIntegration } from "@sentry/integrations";
import { init, replayIntegration, ErrorBoundary } from "@sentry/react";
import LogRocket from "logrocket";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { SWRConfig } from "swr";

import {
  config_sentryDSN,
  config_REACT_APP_STAGE
} from "./config";
import Core from "./pages/Core";
import ReloadPage from "./pages/Error/ReloadPage";
import store from "./redux/store";

init({
  environment  : config_REACT_APP_STAGE,
  dsn          : config_sentryDSN,
  integrations : [
    browserTracingIntegration(),
    captureConsoleIntegration(["error"]),
    replayIntegration({
      maskAllText   : false,
      blockAllMedia : false,
    }),
  ],
  sendDefaultPii           : true,
  tracesSampleRate         : 1.0,
  replaysSessionSampleRate : 0.1,
  denyUrls                 : [
    // Block errors from node_modules
    /node_modules/,
  ],
  replaysOnErrorSampleRate: 1.0,
  beforeSend(event) {
    // Check if the hostname is localhost
    if (window.location.hostname === "localhost") {
      return null;
    }
    return event;
  },
});

// init logrocket only in production
if (config_REACT_APP_STAGE === "production") {
  LogRocket.init("8gahip/series");
}

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <HelmetProvider>
        <ErrorBoundary fallback={<ReloadPage />}>
          <SWRConfig value={{
            revalidateIfStale     : false,
            revalidateOnFocus     : false,
            revalidateOnReconnect : false,
            errorRetryCount       : 0
          }}
          >
            <Core />
          </SWRConfig>
        </ErrorBoundary>
      </HelmetProvider>
    </BrowserRouter>
  </Provider>
);
