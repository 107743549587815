import React, { lazy } from "react";

import PropTypes from "prop-types";

import DialogWrapper from "./dialog-wrapper";
import PrivyWatcher from "./privy-watcher";
import Routes from "./routes";
import WebhookEventHandler from "./webhook-event-handler";

const ReloadPage = lazy(() => import("src/pages/Error/ReloadPage"));

const Content = ({ hasError }) => {
  // Failed to load UI (unknown)
  if (hasError) {
    return <ReloadPage />;
  }

  // Render
  return (
    <>
      <Routes />
      <DialogWrapper />
      <PrivyWatcher />
      <WebhookEventHandler />
    </>
  );
};

Content.propTypes = {
  hasError: PropTypes.bool,
};

export default Content;
