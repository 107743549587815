/* eslint-disable */
import React, { lazy } from "react";

import { Routes, Route, Navigate } from "react-router-dom";

import { config_REACT_APP_STAGE } from "src/config";
import { ROUTE_PATH, AUTH_LEVEL, APP_STAGE } from "src/constants";

const NavigatorSeries = lazy(() => import("src/components/NavigatorSeries"));
const NavigatorSyndicate = lazy(() => import("src/components/NavigatorSyndicate"));
const NotFound = lazy(() => import("src/pages/Error/NotFound"));
const Sanctioned = lazy(() => import("src/pages/Error/Sanctioned"));
const UploadDocument = lazy(() => import("src/pages/UploadDocument"));
const InvitationCode = lazy(() => import("src/pages/InvitationCode"));
const RootRedirect = lazy(() => import("src/pages/RootRedirect"));
const allRoutes = [
  {
    exact          : true,
    path           : ROUTE_PATH.ROOT,
    componentLevel : AUTH_LEVEL.FREE,
    component      : lazy(() => import("src/pages/Root")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.AUTH,
    componentLevel : AUTH_LEVEL.SIGN_IN,
    component      : lazy(() => import("src/pages/Auth")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.SERIES_NOT_PUBLISHED,
    componentLevel : AUTH_LEVEL.SIGN_IN,
    component      : lazy(() => import("src/pages/SeriesNotPublished")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.INVITE_DISABLED,
    componentLevel : AUTH_LEVEL.DASHBOARD,
    component      : lazy(() => import("src/pages/InviteDisabled")),
  },
  {
    exact          : true,
    path           : ROUTE_PATH.INVALID_INVITE,
    componentLevel : AUTH_LEVEL.DASHBOARD,
    component      : lazy(() => import("src/pages/InvalidInvite")),
  },
  {
    exact          : true,
    hasFooter      : true,
    path           : ROUTE_PATH.US,
    componentLevel : AUTH_LEVEL.DASHBOARD,
    component      : lazy(() => import("src/pages/Main")),
  },
  {
    exact          : true,
    hasFooter      : true,
    path           : ROUTE_PATH.INTERNATIONAL,
    componentLevel : AUTH_LEVEL.DASHBOARD,
    component      : lazy(() => import("src/pages/Main")),
  },
  {
    exact          : true,
    hasFooter      : true,
    path           : ROUTE_PATH.START,
    componentLevel : AUTH_LEVEL.DASHBOARD,
    component      : lazy(() => import("src/pages/Start")),
  },
  {
    exact          : true,
    hasFooter      : true,
    path           : ROUTE_PATH.PUBLIC,
    componentLevel : AUTH_LEVEL.PUBLIC,
    component      : lazy(() => import("src/pages/Public")),
  },
  {
    exact          : true,
    hasFooter      : true,
    path           : ROUTE_PATH.AUTH_WITH_SERIES,
    componentLevel : AUTH_LEVEL.DASHBOARD,
    component      : lazy(() => import("src/pages/AuthWithSeries")),
  },
  {
    exact          : true,
    hasFooter      : true,
    path           : ROUTE_PATH.KYB_FLOW,
    componentLevel : AUTH_LEVEL.DASHBOARD,
    component      : lazy(() => import("src/pages/KYBFlow")),
  },
  {
    exact          : true,
    hasFooter      : true,
    path           : ROUTE_PATH.GRANTS,
    componentLevel : AUTH_LEVEL.DASHBOARD,
    component      : lazy(() => import("src/pages/Grants")),
  },
  {
    exact          : true,
    hasFooter      : true,
    path           : ROUTE_PATH.FARCASTER_NETWORK,
    componentLevel : AUTH_LEVEL.DASHBOARD,
    component      : lazy(() => import("src/pages/FarcasterNetwork")),
  },
  {
    devOnly        : true,
    exact          : true,
    hasFooter      : true,
    path           : "test-page",
    componentLevel : AUTH_LEVEL.DASHBOARD,
    component      : lazy(() => import("src/pages/test_page")),
  },
];

const TestComponent = () => {
    const onBtnClick =()=> {
        throw Error("This is mock error triggered inside studio prod to test code unminfy ")
    }
return (
<div><button onClick={onBtnClick} >trigger me</button></div>
    )
}

const routeViews = config_REACT_APP_STAGE === APP_STAGE.DEV
  ? allRoutes
  : allRoutes.filter(route => !route.devOnly);

const AppRoutes = () => (
  <main>
    <Routes>
      <Route path="/syndicate/:slug">
        {routeViews.map(route => (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            componentLevel={route.componentLevel}
            element={(
              <NavigatorSyndicate
                componentLevel={route.componentLevel}
                permission={route.permission}
                path={route.path}
              >
                <route.component  />
              </NavigatorSyndicate>
            )}
          />
        ))}
      </Route>
      <Route path="/series/:slug">
        {routeViews.map(route => (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            componentLevel={route.componentLevel}
            element={(
              <NavigatorSeries
                componentLevel={route.componentLevel}
                permission={route.permission}
                path={route.path}
              >
                <route.component  />
              </NavigatorSeries>
            )}
          />
        ))}
      </Route>
      <Route
        path="/404"
        exact
        element={<NotFound />}
      />
      <Route
        path="/sanctioned"
        exact
        element={<Sanctioned />}
      />
      <Route
        path="/upload-documentation"
        exact
        element={<UploadDocument />}
      />
      <Route
        path="/invitation/:invitationCode"
        exact
        element={<InvitationCode />}
      />
      <Route
        path="/"
        exact
        element={<RootRedirect />}
      />
      <Route path="/sentry-error-test" element={<TestComponent />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  </main>
);

export default AppRoutes;
